import { AUTH } from "../../constants/action-types";

const initialState = {
    user_login: []
};

export default function auth(state = initialState, action) {
    switch (action.type) {
        case AUTH.SIGNIN: {
            return Object.assign({}, state, {
                user_login: action
            });
        }
        default:
            return state
    }
}