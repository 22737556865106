import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from "react-redux";

//Auth Login
const Login = React.lazy(() => import('./Auth/Login'));


//Containers 
import Containers from './Containers/Containers';

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            authenticated: false
        }
    }

    componentWillReceiveProps(nextProps) {
        const { session } = nextProps
        this.setState({
            loading: false,
            authenticated: session.authenticated
        })
    }

    render() {
        const state = this.state
        let isHostForm = false; 

        if (window.location.hash == "#/hostform")
            isHostForm = true;

        return (
            < HashRouter >
                {
                    state.loading === true ? (
                        <p>Loading...</p>
                    ) : (
                            <React.Suspense fallback={loading}>
                                <Switch>
                                    {state.authenticated === false ?
                                        (
                                            <>
                                                <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
                                                <Redirect from='*' to='/login' />

                                            </>
                                        )
                                        :
                                        <Route path="/" name="Home" render={props => <Containers {...props} />} />
                                    }
                                </Switch>
                            </React.Suspense>
                        )
                }

            </HashRouter >
        );
    }
}

function mapStateToProps(state) {
    return {
        session: state.session
    };
}
export default connect(mapStateToProps, {})(App);