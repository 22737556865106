import { COURSES } from "../../constants/action-types";

const initialState = {
    all_courses: [],
    create_course: [],
    delete_course: [],
    publish_course: [],
    unpublish_course: [],
    course_info: [],
    update_course: [],
};

export default function courses(state = initialState, action) {
    switch (action.type) {
        case COURSES.GET_ALL: {
            return Object.assign({}, state, {
                all_courses: action
            });
        }
        case COURSES.CREATE_COURSE: {
            return Object.assign({}, state, {
                create_course: action
            });
        }
        case COURSES.DELETE_COURSE: {
            return Object.assign({}, state, {
                delete_course: action
            });
        }
        case COURSES.PUBLISH_COURSE: {
            return Object.assign({}, state, {
                publish_course: action
            });
        }
        case COURSES.UNPUBLISH_COURSE: {
            return Object.assign({}, state, {
                unpublish_course: action
            });
        }
        case COURSES.GET_COURSE_BY_ID: {
            return Object.assign({}, state, {
                course_info: action
            });
        }
        case COURSES.UPDATE_COURSE: {
            return Object.assign({}, state, {
                update_course: action
            });
        }
        default:
            return state
    }
}