import { ORDERS } from "../../constants/action-types";

const initialState = {
    all_orders: [],
    create_order: [],
    delete_order: [],
    order_info: [],
    update_order: [],
};

export default function orders(state = initialState, action) {
    switch (action.type) {
        case ORDERS.GET_ALL: {
            return Object.assign({}, state, {
                all_orders: action
            });
        }
        case ORDERS.CREATE_ORDER: {
            return Object.assign({}, state, {
                create_order: action
            });
        }
        case ORDERS.DELETE_ORDER: {
            return Object.assign({}, state, {
                delete_order: action
            });
        }
        case ORDERS.GET_ORDER_BY_ID: {
            return Object.assign({}, state, {
                order_info: action
            });
        }
        case ORDERS.UPDATE_ORDER: {
            return Object.assign({}, state, {
                update_order: action
            });
        }
        default:
            return state
    }
}