import React from 'react'
import {
    CBadge,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CImg
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { useHistory } from "react-router-dom";
import { sessionService } from 'redux-react-session';
import avatars from '../../../media/images/avatars.jpg';


const HeaderDropdown = () => {
    const history = useHistory();

    function singout() {
        sessionService.deleteSession();
        sessionService.deleteUser();
        localStorage.removeItem("token");
        setTimeout(function () {
            history.push("/login");
            window.location.reload();
        }, 200)
    }



    return (
        <CDropdown
            inNav
            className="c-header-nav-items mx-2"
            direction="down"
        >
            <CDropdownToggle className="c-header-nav-link" caret={false}>
                <div className="c-avatar">
                    <CImg
                        src={avatars}
                        className="c-avatar-img"
                        alt="admin@bootstrapmaster.com"
                    />
                </div>
            </CDropdownToggle>
            <CDropdownMenu className="pt-0" placement="bottom-end">

                <CDropdownItem
                    header
                    tag="div"
                    color="light"
                    className="text-center"
                >
                    <strong>Settings</strong>
                </CDropdownItem>
                <CDropdownItem>
                    <CIcon name="cil-user" className="mfe-2" />Profile
                            </CDropdownItem>
                <CDropdownItem>
                    <CIcon name="cil-settings" className="mfe-2" />Settings
                            </CDropdownItem>
                <CDropdownItem divider />
                <CDropdownItem onClick={singout}>
                    <CIcon name="cil-lock-locked" className="mfe-2" />Signout
                            </CDropdownItem>
            </CDropdownMenu>
        </CDropdown>
    )
}

export default HeaderDropdown
