
export const IS_SUCCESS = "IS_SUCCESS";

export const AUTH = {
  SIGNIN: "SIGNIN",
};

export const COMMON = {
  UPLOADFILE: "UPLOADFILE",
};

export const TEAMS = {
  GET_ALL: "GET_ALL",
  DELETE_TEAM: "DELETE_TEAM",
  GET_TEAM_BY_ID: "GET_TEAM_BY_ID",
  UPDATE_TEAM: "UPDATE_TEAM",
  CREATE_TEAM: "CREATE_TEAM",
};

export const USERS = {
  GET_ALL: "GET_ALL",
  CREATE_USER: "CREATE_USER",
  DELETE_USER: "DELETE_USER",
  GET_USER_BY_ID: "GET_USER_BY_ID",
  UPDATE_USER: "UPDATE_USER",
};

export const COURSES = {
  GET_ALL: "GET_ALL",
  CREATE_COURSE: "CREATE_COURSE",
  DELETE_COURSE: "DELETE_COURSE",
  PUBLISH_COURSE: "PUBLISH_COURSE",
  UNPUBLISH_COURSE: "UNPUBLISH_COURSE",
  GET_COURSE_BY_ID: "GET_COURSE_BY_ID",
  UPDATE_COURSE: "UPDATE_COURSE",
};

export const ORDERS = {
  GET_ALL: "GET_ALL",
  CREATE_ORDER: "CREATE_ORDER",
  DELETE_ORDER: "DELETE_ORDER",
  UPDATE_ORDER: "UPDATE_ORDER",
  GET_ORDER_BY_ID: "GET_ORDER_BY_ID",
};

export const ICONS = {
  GET_ALL: "GET_ALL",
  CREATE_ICONS: "CREATE_ICONS",
  DELETE_ICONS: "DELETE_ICONS",
  GET_ICONS_BY_ID: "GET_ICONS_BY_ID",
  UPDATE_ICONS: "UPDATE_ICONS",
};
