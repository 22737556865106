import React from "react";
import { render } from "react-dom";
import store from "./redux/store";
import { Provider } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import './assets/css/font-awesome.min.css'

import App from './components/App';

import { icons } from './assets/icons';

React.icons = icons;

render(
    <Provider store={store}>
        <App />
    </Provider>
    , document.getElementById("root")
);