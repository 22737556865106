export function loaderStart() {
    document.getElementById("overlay").style.display = "block"
}

export function loaderEnd() {

    document.getElementById("overlay").style.display = "none"
}
export const setCurrentUser = (user) => {
    try {
      if (user) {
        localStorage.setItem("token", JSON.stringify(user));
      } else {
        localStorage.removeItem("token");
        // localStorage.removeItem("__oval_theme_selected_color");
        localStorage.clear();
      }
    } catch (error) {}
  };
export function convertToSlug(Text) {
    return Text
        .toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-');
}