import { combineReducers } from 'redux'
import { sessionReducer } from 'redux-react-session';
import auth from './Auth/Auth';
import users from './Users/Users';
import teams from './Teams/Teams';
import courses from './Courses';
import orders from './Orders';
import icons from './Icons';

export default combineReducers({
    session: sessionReducer,
    auth,
    users,
    courses,
    icons,
    teams,
    orders
});