export const GENRAL_QUESTIONS = {
  BOOLEAN: "BOOLEAN",
  OPTIONS: "OPTIONS",
  OPTIONS_WITH_INPUT: "OPTIONS_WITH_INPUT",
  TEXT: "TEXT",
  INT: "INT",
  CHECKBOX_WITH_INPUT: "CHECKBOX_WITH_INPUT",
};

export const BASE_URL = "https://oval-api-prod.herokuapp.com/api";
// export const BASE_URL = "http://localhost:8800/api";

export const TEAMS_BASE_URL = "https://oval-teams-api.herokuapp.com/api";
// export const TEAMS_BASE_URL = "http://localhost:8000/api";
