import React, { Component, } from 'react'
import {
    CHeader,
    CToggler,
    CHeaderNav,
    CHeaderNavItem,
    CHeaderNavLink,
    CSubheader,
    CBreadcrumbRouter
} from '@coreui/react';
import { sessionService } from 'redux-react-session';

import HeaderDropdown from './HeaderDropdown';
// routes config
import routes from '../../routes'

export class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebarShow: false
        }
    }

    // Things to do before unloading/closing the tab
    doSomethingBeforeUnload = () => {  
        debugger       
        sessionService.deleteSession();
        sessionService.deleteUser();
        localStorage.removeItem("token");
    }

    // Setup the `beforeunload` event listener
    setupBeforeUnloadListener = () => {
        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
            //return this.doSomethingBeforeUnload();
        });
    };

    componentDidMount() {
        // Activate the event listener
        this.setupBeforeUnloadListener();
    }

    render() {
        return (
            <CHeader withSubheader>
                <CToggler
                    inHeader
                    className="ml-md-3 d-lg-none"
                    onClick={this.props.handleSideBar}
                />
                <CToggler
                    inHeader
                    className="ml-3 d-md-down-none"
                    onClick={this.props.handleSideBar}
                />
                <CHeaderNav className="d-md-down-none mr-auto">
                    <CHeaderNavItem className="px-3" >
                        <CHeaderNavLink to="/dashboard">Dashboard</CHeaderNavLink>
                    </CHeaderNavItem>                     
                </CHeaderNav>

                <CHeaderNav className="px-3">
                    <HeaderDropdown />
                </CHeaderNav>

                <CSubheader className="px-3 justify-content-between">
                    <CBreadcrumbRouter
                        className="border-0 c-subheader-nav m-0 px-0 px-md-3"
                        routes={routes}
                    />
                </CSubheader>
            </CHeader>
        );
    }
}

export default Header