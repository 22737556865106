import axios from "axios";
import { BASE_URL } from "../../helpers/constants";
import { loaderEnd, loaderStart } from "../../helpers/Util";
import toast from "../../helpers/toast";

// import { servicePath } from "constants/defaultValues";

const instance = axios.create({
  baseURL: BASE_URL,
});

instance.interceptors.request.use(
  async (config) => {
    loaderStart();
    
    const token = JSON.parse(localStorage.getItem("token"));
    // const token = localStorage.getItem("token").accessToken
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token.accessToken}`,
      };
    }
    return config;
  },
  (err) => {
    loaderEnd();
    Promise.reject(err);
  }
);
instance.interceptors.response.use(
  async (response) => {
    if (
      response.config.method != "get" &&
      (response.data.error === true || response.data?.type === "Error")
    ) {
      toast.error(response.data.message);
    } else if (response.config.method != "get" && response.data.message) {
      toast.success(response.data.message);
    }
    loaderEnd();

    return response;
  },
  (err) => {
    if (err.response) toast.error(err.response.data);
    loaderEnd();
    Promise.reject(err);
  }
);
export default instance;
