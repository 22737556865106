import React, { Component } from 'react'
import Sidebar from './Sidebar/Sidebar';
import Header from "./Header/Header";
import Footer from './Footer/Footer';
import Content from './Content/Content';
import '../../styles/scss/style.scss';

export class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebarShow: true
        }
    }

    handleSideBar = () => this.setState({ sidebarShow: !this.state.sidebarShow });

    render() {
     
        return (
            <div className="c-app c-default-layout">
                <Sidebar sidebarShow={this.state.sidebarShow} handleSideBar={this.handleSideBar} />
                <div className="c-wrapper">
                    <Header handleSideBar={this.handleSideBar} />
                    <div className="c-body">
                        <Content />
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default Dashboard