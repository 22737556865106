export default [
    {
        _tag: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-speedometer',
    },
    {
        _tag: 'CSidebarNavTitle',
        _children: ['USER MANAGEMENT']
    },
    {
        _tag: 'CSidebarNavItem',
        name: 'Users',
        to: '/users',
        icon: 'cilUser',
    },
    {
        _tag: 'CSidebarNavTitle',
        _children: ['Template MANAGEMENT']
    },
    {
        _tag: 'CSidebarNavItem',
        name: 'Templates',
        to: '/templates',
        icon: 'cilFile',
    },
    {
        _tag: 'CSidebarNavTitle',
        _children: ['Order MANAGEMENT']
    },
    {
        _tag: 'CSidebarNavItem',
        name: 'Orders',
        to: '/orders',
        icon: 'cilFile',
    },
    {
        _tag: 'CSidebarNavTitle',
        _children: ['Icon MANAGEMENT']
    },
    {
        _tag: 'CSidebarNavItem',
        name: 'Icons',
        to: '/icons',
        icon: 'cilFile',
    },
    {
        _tag: 'CSidebarNavTitle',
        _children: ['Teams MANAGEMENT']
    },
    {
        _tag: 'CSidebarNavItem',
        name: 'Oval Teams',
        to: '/teams',
        icon: 'cilUser',
    },
    {
        _tag: 'CSidebarNavItem',
        name: 'Dedicated Teams',
        to: '/dedicated-teams',
        icon: 'cilUser',
    },

]

