import * as constants from "../redux/constants/action-types";

export function Middleware({ dispatch }) {
    return function (next) {
        return function (action) {
            // do your stuff
      

            if (action.type === constants.USERS.GET_ALL) {
                for (let index in action.payload) {
                    action.payload[index].createdAt = new Date(action.payload[index].createdAt).toLocaleString();
                    action.payload[index].updatedAt = new Date(action.payload[index].updatedAt).toLocaleString();
                }
            }

            return next(action);
        };
    };
}