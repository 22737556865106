import { TEAMS } from "../../constants/action-types";

const initialState = {
    all_teams: [],
    create_team: [],
    delete_team: [],
    team_info: [],
    update_team: [],
    create_team: [],
};

export default function teams(state = initialState, action) {
    switch (action.type) {
        case TEAMS.GET_ALL: {
            return Object.assign({}, state, {
                all_teams: action
            });
        }
        case TEAMS.DELETE_TEAM: {
            return Object.assign({}, state, {
                delete_team: action
            });
        }
        case TEAMS.GET_TEAM_BY_ID: {
            return Object.assign({}, state, {
                team_info: action
            });
        }
        case TEAMS.UPDATE_TEAM: {
            return Object.assign({}, state, {
                update_team: action
            });
        }
        case TEAMS.CREATE_TEAM: {
            return Object.assign({}, state, {
                create_team: action
            });
        }

        default:
            return state
    }
}