import 'izitoast/dist/css/iziToast.min.css'
import iZtoast from 'izitoast'

iZtoast.settings({
    timeout: 5000,
    transitionIn: 'fadeInUp',
    transitionOut: 'fadeOut',
    transitionInMobile: 'fadeInUp',
    transitionOutMobile: 'fadeOutDown',
    position: 'bottomCenter',
    messageColor: "#ffffff"
});

const toast = {
    error: (message, title = '') => {
        return iZtoast.error({
            title: title,
            message: message
        });
    },
    warning: (message, title = '') => {
        return iZtoast.warning({
            title: title,
            message: message
        });
    },
    info: (message, title = '') => {
        return iZtoast.info({
            title: title,
            message: message
        });
    },
    success: (message, title = '') => {
        return iZtoast.success({
            title: title,
            message: message
        });
    }
};

export default toast;