import React from 'react';

const Dashboard = React.lazy(() => import('./Views/Dashboard/Dashboard'));
const Users = React.lazy(() => import('./Views/Users/Users'));
const UserForm = React.lazy(() => import('./Views/Users/UserForm'));

const Teams = React.lazy(() => import('./Views/Teams/Teams'));
const AddTeams = React.lazy(() => import('./Views/Teams/Add'));
const TeamsForm = React.lazy(() => import('./Views/Teams/Form')); 

const DedicatedTeams = React.lazy(() => import('./Views/DedicatedTeams/Teams'));
const DedicatedTeamsForm = React.lazy(() => import('./Views/DedicatedTeams/Form')); 

const Templates = React.lazy(() => import('./Views/Templates'));
const TemplateForm = React.lazy(() => import('./Views/Templates/Form')); 

const Orders = React.lazy(() => import('./Views/Orders'));
const OrderForm = React.lazy(() => import('./Views/Orders/Form')); 
const OrderDetail = React.lazy(() => import('./Views/Orders/OrderDetail')); 

const Icons = React.lazy(() => import('./Views/Icons'));
const IconForm = React.lazy(() => import('./Views/Icons/Form')); 
 
const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },

  { path: '/users', exact: true, name: 'Users', component: Users },
  { path: '/users/add', exact: true, name: 'Add User', component: UserForm },
  { path: '/users/edit/:id', exact: true, name: 'Edit User', component: UserForm }, 

  { path: '/templates', exact: true, name: 'Templates', component: Templates },
  { path: '/templates/add', exact: true, name: 'Add Template', component: TemplateForm },
  { path: '/templates/edit/:id', exact: true, name: 'Edit Template', component: TemplateForm },
  
  { path: '/orders', exact: true, name: 'Orders', component: Orders },
  { path: '/orders/:id', exact: true, name: 'Order Detail', component: OrderDetail }, 
  { path: '/orders/edit/:id', exact: true, name: 'Edit Order', component: OrderForm }, 

  { path: '/icons', exact: true, name: 'Icons', component: Icons },
  { path: '/icons/add', exact: true, name: 'Add Icon', component: IconForm },
  { path: '/icons/edit/:id', exact: true, name: 'Edit Icon', component: IconForm }, 

  { path: '/teams', exact: true, name: 'Teams', component: Teams },
  { path: '/teams/add', exact: true, name: 'Add Team', component: AddTeams }, 
  { path: '/teams/edit/:id', exact: true, name: 'Edit Team', component: TeamsForm }, 

  { path: '/dedicated-teams', exact: true, name: 'Dedicated Teams', component: DedicatedTeams },
  { path: '/dedicated-teams/edit/:id', exact: true, name: 'Edit Team', component: DedicatedTeamsForm }, 

];

export default routes;
