import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers";
import { Middleware } from "./../../middleware/index";
import thunk from "redux-thunk";
import { sessionService } from 'redux-react-session';
import axios from 'axios';
import instance from "../instance/instanceApiAdmin";

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    storeEnhancers(applyMiddleware(Middleware, thunk))
);

const validateSession = async (session) => {
    let response = await instance.post('/validatetoken', session);
    // check if your session is still valid
    return response.data;
}

const options = { refreshOnCheckAuth: true, redirectPath: '/login', driver: 'COOKIES', validateSession };

sessionService.initSessionService(store, options)
    .then(() => console.log('Redux React Session is ready and a session was refreshed from your storage'))
    .catch(() => console.log('Redux React Session is ready and there is no session in your storage'));

export default store;