import React, { Component, } from 'react';
import {
    CCreateElement,
    CSidebar,
    CSidebarBrand,
    CSidebarNav,
    CSidebarNavDivider,
    CSidebarNavTitle,
    CSidebarMinimizer,
    CSidebarNavDropdown,
    CSidebarNavItem,
} from '@coreui/react';

// sidebar nav config
import navigation from './_nav';

import LargeLogo from '../../../media/images/logo.png';
import SmallLogo from '../../../media/images/logo-small.png';

export class Sidebar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <CSidebar
                show={this.props.sidebarShow}
                onShowChange={this.props.handleSideBar}
            >
                <CSidebarBrand className="d-md-down-none" to="/">
                    <div className="c-sidebar-brand-full">
                        <img src={LargeLogo} alt={LargeLogo} />
                    </div>
                    <div className="c-sidebar-brand-minimized">
                        <img src={SmallLogo} alt={SmallLogo} />
                    </div>
                </CSidebarBrand>
                <CSidebarNav>
                    <CCreateElement
                        items={navigation}
                        components={{
                            CSidebarNavDivider,
                            CSidebarNavDropdown,
                            CSidebarNavItem,
                            CSidebarNavTitle
                        }}
                    />
                </CSidebarNav>
                <CSidebarMinimizer className="c-d-md-down-none" />
            </CSidebar>
        );
    }
}

export default Sidebar