import { USERS } from "../../constants/action-types";

const initialState = {
    all_users: [],
    create_user: [],
    delete_user: [],
    user_info: [],
    update_user: [],
};

export default function users(state = initialState, action) {
    switch (action.type) {
        case USERS.GET_ALL: {
            return Object.assign({}, state, {
                all_users: action
            });
        }
        case USERS.CREATE_USER: {
            return Object.assign({}, state, {
                create_user: action
            });
        }
        case USERS.DELETE_USER: {
            return Object.assign({}, state, {
                delete_user: action
            });
        }
        case USERS.GET_USER_BY_ID: {
            return Object.assign({}, state, {
                user_info: action
            });
        }
        case USERS.UPDATE_USER: {

            return Object.assign({}, state, {
                update_user: action,
                
            });
        }
        default:
            return state
    }
}