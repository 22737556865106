import { ICONS } from "../../constants/action-types";

const initialState = {
    all_icons: [],
    create_icons: [],
    delete_icons: [],
    icons_info: [],
    update_icons: [],
};

export default function icons(state = initialState, action) {
    switch (action.type) {
        case ICONS.GET_ALL: {
            return Object.assign({}, state, {
                all_icons: action
            });
        }
        case ICONS.CREATE_ICONS: {
            return Object.assign({}, state, {
                create_icons: action
            });
        }
        case ICONS.DELETE_ICONS: {
            return Object.assign({}, state, {
                delete_icons: action
            });
        }      
        case ICONS.GET_ICONS_BY_ID: {
            return Object.assign({}, state, {
                icons_info: action
            });
        }
        case ICONS.UPDATE_ICONS: {
            return Object.assign({}, state, {
                update_icons: action
            });
        }
        default:
            return state
    }
}